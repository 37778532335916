import React from 'react';
import Goal from './Goal';

import * as styles from './RoadMap.module.scss';

const RoadMap = () => (
  <div className={styles.RoadMap}>
    <div className={styles.Header}>Road map</div>

    <div className={styles.Images}>
      <Goal state="nashville" term="01" text="Now" active />
      <Goal state="tennessee" term="02" text="Phase 1" />
      <Goal state="kentucky" term="03" text="Phase 2" />
    </div>
  </div>
);

export default RoadMap;
import React from 'react';

import * as styles from './Footer.module.scss';

const Footer = () => (
  <div className={styles.Footer}>
    <div className={styles.Content}>
      <div className={styles.List}>
        <div className={styles.Header}>LEGAL</div>
        <div className={styles.Item}>
          <a href="/terms-of-use">Terms of Use Policy</a>
        </div>
        <div className={styles.Item}>
          <a href="/copyright">Copyright</a>
        </div>
        <div className={styles.Item}>
          <a href="/privacy">Privacy Policy</a>
        </div>
      </div>
      <div className={styles.List}>
        <div className={styles.Header}>CONTACT US</div>
        {/* <div className={styles.Item}>
          <a href="tel:+1 215 987 7815">+1 215 987 7815</a>
        </div> */}
        <div className={styles.Item}>
          <a href="mailto:support@thefitlab.io">support@thefitlab.io</a>
        </div>
      </div>
    </div>
    <div className={styles.Bottom}>
      <div>ARES</div>
      <div>2022</div>
    </div>
  </div>
);

export default Footer;
import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import SignUpInput from "./SignUpInput";
import * as styles from "./SignUp.module.scss";

const SignUp = () => (
  <div className={styles.SignUp}>
    <div className={styles.SignUpContent}>
      <div className={styles.Content}>
        <div className={styles.Title}>Let's get started</div>
        <div className={styles.Info}>
          Use all the benefits of Ares on your next investment decisions and
          deals. Pre-register now and you'll be first in the queue when we
          launch.
        </div>
        <SignUpInput className={styles.SignUpInput} />
        <div className={styles.Image}>
          <StaticImage src="./houses.png" alt="Houses" />
        </div>
      </div>
    </div>
  </div>
);

export default SignUp;

import React from 'react';

import * as styles from './Header.module.scss';

import SignIn from '../signin';

const Header = () => (
  <div className={styles.Header}>
    <div>
      <svg
        width="101"
        height="32"
        viewBox="0 0 101 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.748 31L7.48 1.08H19.008L25.74 31H19.668L18.48 25.588H8.008L6.82 31H0.748ZM12.188 6.096L9.152 20.308H17.336L14.3 6.096H12.188ZM35.7968 20.968V31H29.7248V1.08H41.3408C48.3808 1.08 51.9008 4.36533 51.9008 10.936C51.9008 14.8373 50.4341 17.712 47.5008 19.56L51.7688 31H45.1248L41.6488 20.968H35.7968ZM41.4288 15.776C42.8661 15.776 43.9368 15.336 44.6408 14.456C45.3448 13.576 45.6968 12.4173 45.6968 10.98C45.6968 9.54267 45.3154 8.39867 44.5528 7.548C43.8194 6.69733 42.7488 6.272 41.3408 6.272H35.7968V15.776H41.4288ZM57.0099 31V1.08H76.3699V6.36H63.0819V13.356H73.7299V18.548H63.0819V25.72H76.3699V31H57.0099ZM90.5692 5.832C87.3719 5.832 85.7732 6.888 85.7732 9C85.7732 9.93867 86.1692 10.6573 86.9612 11.156C87.7532 11.6547 89.6012 12.3733 92.5052 13.312C95.4385 14.2507 97.4919 15.3213 98.6652 16.524C99.8385 17.6973 100.425 19.516 100.425 21.98C100.425 25.1187 99.4719 27.4947 97.5652 29.108C95.6585 30.7213 93.1652 31.528 90.0852 31.528C87.7972 31.528 84.9959 31.176 81.6812 30.472L80.0972 30.164L80.7132 25.456C84.6439 25.984 87.6505 26.248 89.7332 26.248C92.8425 26.248 94.3972 24.9573 94.3972 22.376C94.3972 21.4373 94.0305 20.704 93.2972 20.176C92.5932 19.648 91.1705 19.076 89.0292 18.46C85.6265 17.492 83.2212 16.348 81.8132 15.028C80.4345 13.6787 79.7452 11.816 79.7452 9.44C79.7452 6.448 80.6545 4.21867 82.4732 2.752C84.2919 1.28533 86.7852 0.551999 89.9532 0.551999C92.1239 0.551999 94.8812 0.845332 98.2252 1.432L99.8092 1.74L99.3252 6.536C95.1892 6.06667 92.2705 5.832 90.5692 5.832Z"
          fill="#EAF8F9"
        />
      </svg>
    </div>
    <div>
      <SignIn />
    </div>
  </div>
);

export default Header;
import React from 'react';
import { StaticImage } from "gatsby-plugin-image";

import * as styles from './Preview.module.scss';

const Preview = () => (
  <div className={styles.Preview}>
    <div className={styles.ImageContainer}>
      <StaticImage className={styles.Image} src="./preview.png" alt="Preview" />
    </div>
  </div>
);

export default Preview;
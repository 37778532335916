import React from 'react';
import { StaticImage } from "gatsby-plugin-image";

import * as styles from './Fourth.module.scss';

const Fourth = () => (
  <div className={styles.Fourth}>
    <div className={styles.Ellipse}><StaticImage src="./mapEllipse.png" alt="mapEllipse" /></div>
    <div className={styles.Houses}><StaticImage src="./3-houses.png" alt="Houses" /></div>
  </div>
);

export default Fourth;
import React from 'react';

import Nashville from './Nashville';
import Tennessee from './Tennessee';
import Kentucky from './Kentucky';

import * as styles from './Goal.module.scss';

const IMAGE_MAP = {
  'nashville': Nashville,
  'tennessee': Tennessee,
  'kentucky': Kentucky,
}

const Goal = ({ state, term, text, active }) => {
  const Image = IMAGE_MAP[state];
  const className = active ? `${styles.Footer} active` : styles.Footer;
  return (
    <div className={styles.Goal}>
      <div>
        <Image className={styles.Svg} />
      </div>
      <div className={className}>
        <div className={styles.Term}>{term}</div>
        <div className={styles.Text}>{text}</div>
      </div>
    </div>
  );
};

export default Goal;
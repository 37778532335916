import React from 'react';
import { StaticImage } from "gatsby-plugin-image";

import * as styles from './Second.module.scss';

const Second = () => (
  <div className={styles.Second}>
    <div className={styles.Ellipse}><StaticImage src="./mapEllipse.png" alt="mapEllipse" /></div>
    <div className={styles.Houses}><StaticImage src="./2-houses.png" alt="Houses" /></div>
  </div>
);

export default Second;
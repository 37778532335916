/* eslint-disable jsx-a11y/mouse-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/no-static-element-interactions */
import React from 'react';

import House from './House';
import Money from './Money';
import Bulb from './Bulb';
import Estimation from './Estimation';

import * as styles from './Feature.module.scss';

const IMAGE_MAP = {
  'money': Money,
  'house': House,
  'bulb': Bulb,
  'estimation': Estimation,
}

const Feature = ({ onHover, isActive, type, children }) => {
  const Image = IMAGE_MAP[type];
  const className = isActive ? `${styles.Feature} is-active` : `${styles.Feature}` ;
  return (
    <div className={className} onMouseOver={onHover}>
      <div className={styles.Image}>
        <Image inverted={isActive} />
      </div>
      <div>{children}</div>
      <div className={styles.Arrow} />
    </div>
  );
};

export default Feature;
import React from 'react';

import * as styles from './WhatIs.module.scss';

const WhatIs = () => (
  <div className={styles.WhatIs}>
    <div className={styles.Left}>
      <div className={styles.Title}>What`s<br />Ares?</div>
    </div>
    <div className={styles.Right}>
      <div className={styles.SubTitle}>New. Unique. Affordable</div>
      <div className={styles.Text}>
        Our goal is to provide the easiest way to make investment decisions.
        That's why an efficient and reliable service provides the most information
        about investment appeal based on daily Tennessee, Kentucky, Georgia real
        estate market analysis.
      </div>
    </div>
  </div>
);

export default WhatIs;
import React from "react";

import Photo from './Photo';

import * as styles from "./Team.module.scss";

const Team = () => (
  <div className={styles.Team}>
    <div className={styles.Content}>
      <div className={styles.ImageContainer}>
        <Photo className={styles.Image} />
      </div>
      <div className={styles.Text}>
        <div className={styles.Title}>Our team</div>
        <div className={styles.Info}>
          We take care of our customers and our teammates. If you have any
          questions, send an email to
        </div>
        <div className={styles.Email}>
          <a href="mailto:support@thefitlab.io">support@thefitlab.io</a>
        </div>
      </div>
    </div>
  </div>
);

export default Team;

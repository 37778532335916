import React from 'react';

import * as styles from './TopArea.module.scss';

import { SignUpInput} from "../signup";

const TopArea = () => (
  <div className={styles.TopArea}>
    <div className={styles.Title}>
      <div>AI-powered</div>
      <div>analytics</div>
    </div>
    <div className={styles.SubTitle}>
      <div>Count Ares for making investment decisions easier</div>
    </div>
    <div className={styles.SignUpInput}>
      <SignUpInput />
    </div>
    <div className={styles.SignUpText}>
      Pre-register now and you'll be first in the queue when we launch.
    </div>
  </div>
);

export default TopArea;
import React, { useRef, useState } from "react";

import * as styles from "./SignUpInput.module.scss";

const validateEmail = (email) =>
  /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);

const SignUpInput = ({ className }) => {
  const inputRef = useRef();
  const [isValid, setIsValid] = useState(true);
  const [submitted, setSubmitted] = useState(false);

  const onSubmit = (e) => {
    const email = inputRef.current.value?.toLowerCase();
    if (!validateEmail(email)) {
      setIsValid(false);
      return;
    }
    fetch("https://api.ares.homes/api/presignup", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    })
      .then(() => {
        setSubmitted(true);
      })
      .catch((e) => {
        setSubmitted(true);
      });
  };

  const inputClass = isValid ? `${styles.Input}` : `${styles.Input} invalid`;

  return (
    <div className={className}>
      {!submitted && (
        <div className={inputClass}>
          <input
            onChange={() => setIsValid(true)}
            name="email"
            type="email"
            placeholder="Enter your mail"
            ref={inputRef}
          />
          <button className={styles.InlineInput} onClick={onSubmit}>Subscribe</button>
        </div>
      )}
      {submitted && (
        <span className={styles.Thanks}>Thanks, we will contact you back!</span>
      )}
      <button className={styles.BottomInput} onClick={onSubmit}>Subscribe</button>
    </div>
  );
};

export default SignUpInput;

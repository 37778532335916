import React, { useState } from 'react';

import First from './First';
import Second from './Second';
import Third from './Third';
import Fourth from './Fourth';

import Feature from './Feature';

import * as styles from './Overview.module.scss';

const LEFT_SIDE_MAP = {
  1: First,
  2: Second,
  3: Third,
  4: Fourth
}

const Overview = () => {
  const [selected, setSelected] = useState(1);
  
  const Component = LEFT_SIDE_MAP[selected];

  return (
    <div className={styles.Overview}>
      <div className={styles.Content}>
        <div className={styles.Left}>
          <div>
            <div className={styles.Title}>
              You`ll have the tools to make the work of real estate agents and
              investors more effective:
            </div>
            <div className={styles.Features}>
              <Feature
                type="money"
                isActive={selected === 1}
                onHover={() => setSelected(1)}
              >
                Manual tool for off-market property estimation
              </Feature>
              <Feature
                type="bulb"
                isActive={selected === 2}
                onHover={() => setSelected(2)}
              >
                Close to real-time opportunities evaluation
              </Feature>
              <Feature
                type="house"
                isActive={selected === 3}
                onHover={() => setSelected(3)}
              >
                On the fly ROI estimation for every market object
              </Feature>
              <Feature
                type="estimation"
                isActive={selected === 4}
                onHover={() => setSelected(4)}
              >
                AI-powered rental price estimation driven by daily market
                insight
              </Feature>
            </div>
          </div>
        </div>
        <div className={styles.Right}>
          <Component />
        </div>
      </div>
    </div>
  );
};

export default Overview;

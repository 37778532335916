import React from "react";
import { OutboundLink } from "gatsby-plugin-google-gtag";

import * as styles from "./SignIn.module.scss";

const SignIn = () => (
  <div className={styles.SignIn}>
    <div>
      <OutboundLink
        href="https://app.ares.homes"
        target="_blank"
        rel="noreferrer"
        className={styles.SignInLink}
      >
        Log In
      </OutboundLink>
    </div>
    <div>
      <OutboundLink
        href="https://app.ares.homes/register"
        target="_blank"
        rel="noreferrer"
        className={styles.SignUpLink}
      >
        Sign Up
      </OutboundLink>
    </div>
  </div>
);

export default SignIn;

/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
import * as React from "react";

import Header from "../components/header";
import Footer from "../components/footer";
import TopArea from "../components/toparea";
import WhatIs from "../components/whatis";
import SignUp from "../components/signup";
import Team from "../components/team";
import RoadMap from "../components/roadmap";
import Preview from "../components/preview";
import Overview from "../components/overview";

import * as styles from "./index.module.scss";

const IndexPage = () => {
  const [inTheEnd, setInTheEnd] = React.useState(false);
  const [blocks, setBlocks] = React.useState([]);
  const topRef = React.useRef();
  const whatIsRef = React.useRef();
  const overviewRef = React.useRef();
  const roadMapRef = React.useRef();
  const ourTeamRef = React.useRef();
  const signUpRef = React.useRef();

  React.useEffect(() => {
    setBlocks([
      { element: whatIsRef, position: whatIsRef.current.offsetTop},
      { element: overviewRef, position: overviewRef.current.offsetTop},
      { element: roadMapRef, position: roadMapRef.current.offsetTop},
      { element: ourTeamRef, position: ourTeamRef.current.offsetTop},
      { element: signUpRef, position: signUpRef.current.offsetTop},
    ]);
  }, []);

  const onScroll = React.useCallback((e) => {
    if (!blocks.length) {
      return;
    }

    if (blocks[blocks.length - 2].position + 100 < window.pageYOffset) {
      setInTheEnd(true);
    } else {
      setInTheEnd(false);
    }
  }, [blocks]);

  React.useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll)
  }, [blocks, onScroll])

  const nextSection = React.useCallback(() => {
    const currentScroll = window.pageYOffset;
    const next = blocks.findIndex((e) => e.position > currentScroll + 10);
    blocks[next].element.current.scrollIntoView({ behavior: 'smooth' });
  }, [blocks]);

  const scrollToTop = React.useCallback(() => {
    topRef.current.scrollIntoView({ behavior: 'smooth' });
  }, []);

  return (
    <div ref={topRef}>
      <div className={styles.BackgroundImage}>
        <Header />
        <TopArea />
      </div>
      <div>
        <div ref={whatIsRef}>
          <WhatIs />
        </div>
        <div>
          <Preview />
        </div>
        <div ref={overviewRef}>
          <Overview />
        </div>
        <div ref={roadMapRef}>
          <RoadMap />
        </div>
        <div ref={ourTeamRef}>
          <Team />
        </div>
        <div ref={signUpRef}>
          <SignUp />
        </div>
      </div>
      <div>
        <Footer />
      </div>
      {!inTheEnd && (
        <div onClick={nextSection} className={styles.ScrollNext}>
          <span></span>
        </div>
      )}
      {inTheEnd && (
        <div onClick={scrollToTop} className={styles.ScrollToTop}>
          <span></span>
        </div>
      )}
    </div>
  );
};

export default IndexPage;

import React from 'react';
import { StaticImage } from "gatsby-plugin-image";

import * as styles from './Third.module.scss';

const Third = () => (
  <div className={styles.Third}>
    <svg
      viewBox="0 0 441 441"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={styles.Ellipse}
    >
      <circle
        cx="220.5"
        cy="220.5"
        r="215.5"
        stroke="#EAF8F9"
        strokeOpacity="0.4"
        strokeWidth="10"
      />
    </svg>
    <div className={styles.Image}><StaticImage src="./house-info.png" alt="Info" /></div>
  </div>
);

export default Third;